import React, { lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';


const Home = lazy(() => import('./components/pages/Home'));
const NewsFeed = lazy(() => import('./components/pages/NewsFeed'));
const Search = lazy(() => import('./components/pages/Search'));


const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/news-feed/" component={NewsFeed} />
      <Route path="/search/" component={Search} />
    </Switch>
  </BrowserRouter>
)

export default App
