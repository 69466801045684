const firePerformance = async () => {
      
      const { firebase } = await import("@firebase/app");

      await import('@firebase/performance').then(() => {
        const firebaseConfig = {
          apiKey: "AIzaSyDLUZZmss56vVlMEPII6ddh8XkPUVDDQuk",
          authDomain: "ruben-oliveira.firebaseapp.com",
          projectId: "ruben-oliveira",
          appId: "1:995820431633:web:ac7c156d84a0fb6a"
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        
        // Initialize Performance Monitoring and get a reference to the service
        firebase.performance();
      })

}

export default firePerformance;