import React, { Suspense } from 'react';

import { hydrate, render } from 'react-dom';
import App from './App';

import registerServiceWorker from './utils/registerServiceWorker';
import firebasePerformance from './utils/firebase-monitoring';


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>, rootElement
  );
} else {
  render(
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>, rootElement
  );
}

registerServiceWorker();
firebasePerformance();
